import { Component, inject, Signal } from '@angular/core';
import { LowerCasePipe, NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

import { NotificationService } from '@core/services/notification/notification.service';
import { Notification, NotificationTypeEnum } from '@interfaces';

@Component({
  selector: 'faw-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  imports: [LowerCasePipe, NgClass, MatIcon],
})
export class NotificationsComponent {
  public $notifications: Signal<Notification[]>;
  private notificationService = inject(NotificationService);

  constructor() {
    this.$notifications = this.notificationService.$notifications;
  }

  public iconFor(notification: Notification): string {
    switch (notification.type) {
      case NotificationTypeEnum.INFO:
        return 'info_outline';
      case NotificationTypeEnum.ERROR:
        return 'error_outline';
      default:
        return 'check_circle_outline';
    }
  }
}
