import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerActions } from './router.actions';

@Injectable()
export class RouterEffects {
  private router = inject(Router);
  private actions$ = inject(Actions);

  navigate = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigate),
        tap(({ url, fragment, queryParams, state }) => this.router.navigate([url], { fragment, queryParams, state }))
      ),
    { dispatch: false }
  );
}
