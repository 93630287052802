import { Injectable } from '@angular/core';

import { Observable, of, tap } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { createEffect, ofType } from '@ngrx/effects';
import { BaseEffects } from '@store/base.effects';

import { userActions } from './user.actions';
import { routerActions } from '../router/router.actions';

import { IUser, NotificationTypeEnum } from '@interfaces';

import { LOCALSTORAGE_TOKEN_KEY } from '@core/constants';

@Injectable()
export class UserEffects extends BaseEffects {
  authenticate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.authenticate),

      mergeMap(({ login, password }) =>
        this.api.post<IUser>('/users/auth', { login, password }).pipe(
          switchMap(({ payload, error }) => {
            if (payload) {
              return of(userActions.authenticateSuccess({ user: payload }));
            }

            this.notify('Benutzername und/oder Passwort ungültig', NotificationTypeEnum.ERROR);
            return of(userActions.authenticateFailure({ message: error.message }));
          })
        )
      )
    )
  );

  authenticateSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.authenticateSuccess),
      tap(({ user }) => {
        this.notify(`Willkommen ${user.firstName}`, NotificationTypeEnum.SUCCESS);
      }),
      switchMap(() => of(routerActions.navigate({ url: '/galleries/welcome' })))
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.logout),
      switchMap(() => {
        localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
        return of(routerActions.navigate({ url: '/users/login' }));
      })
    )
  );

  fetchOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.fetchOne),
      mergeMap(({ id, uuid }) =>
        this.api.get<IUser>(`admin/users/${id || uuid}`).pipe(
          map(({ success, payload, error }) => {
            if (payload) {
              return userActions.fetchOneSuccess({ entity: payload });
            }
            return userActions.fetchAllError({ error });
          })
        )
      )
    )
  );

  fetchAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.fetchAll),
      mergeMap(() =>
        this.api.get<IUser[]>('admin/users').pipe(
          map(({ success, payload, error }) => {
            if (payload) {
              return userActions.fetchAllSuccess({
                entities: payload,
              });
            }
            return userActions.fetchAllError({ error });
          })
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.create),
      mergeMap(action =>
        this.api.post<IUser>(`admin/users`, action.entity).pipe(
          map(({ success, payload, error }) => {
            if (success) {
              return userActions.createSuccess({ entity: payload });
            }
            return userActions.createError({ error });
          })
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.update),
      mergeMap(action =>
        this.api.patch<IUser>(`admin/users/${action.entity.id}`, { user: action.entity }).pipe(
          map(({ success, payload, error }) => {
            if (success) {
              this.notify(`Successfully updated ${payload.firstName}`);
              return userActions.updateSuccess({ entity: payload });
            }
            this.notify(error.message, NotificationTypeEnum.ERROR);
            return userActions.updateError({ error });
          })
        )
      )
    )
  );

  updateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateProfile),
      mergeMap(action =>
        this.api.patch<{ user: IUser }>(`users/profile`, { user: action.entity }).pipe(
          map(({ success, payload, error }) => {
            if (success) {
              this.notify('Änderungen gespeichert.', NotificationTypeEnum.SUCCESS);

              return userActions.updateProfileSuccess({ user: payload.user });
            }

            this.notify('Hier ist leider etwas schief gelaufen..', NotificationTypeEnum.ERROR);
            userActions.updateProfileError({ error });
          })
        )
      )
    )
  );

  updateBiometrics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateBiometrics),
      mergeMap(({ userId, biometrics }) =>
        this.api.patch<{ user: IUser }>(`users/${userId}/biometrics`, { user: { biometrics } }).pipe(
          map(({ success, payload, error }) => {
            if (success) {
              return userActions.updateProfileSuccess({
                user: payload.user,
              });
            }
            userActions.updateProfileError({ error });
          })
        )
      )
    )
  );

  destroy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.destroy),
      mergeMap(action =>
        this.api.delete(`admin/users/${action.id}`).pipe(
          map(({ success, payload, error }) => {
            if (success) {
              this.notify(`Successfully deleted User with ID${action.id}`);
              return userActions.destroySuccess({ id: action.id });
            }
            return userActions.destroyError({ error });
          })
        )
      )
    )
  );
}
