import { Component, ElementRef, viewChild, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';

import { selectors as userSelectors } from '@store/user';

import { FooterComponent } from './components/footer/footer.component';
import { GalleryLightboxComponent } from './components/gallery-lightbox/gallery-lightbox.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { NotificationsComponent } from '@core/components/common';
import { ReactiveComponent } from '@core/components/_base/reactive.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';

import { compareEntities } from '@lib/helpers';

@Component({
  selector: 'faw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    NotificationsComponent,
    NotificationsComponent,
    MatSidenavContainer,
    MatSidenavContent,
    MatSidenav,
    RouterOutlet,
    FooterComponent,
    LoadingSpinnerComponent,
    HeaderComponent,
    SideNavComponent,
    GalleryLightboxComponent,
  ],
})
export class AppComponent extends ReactiveComponent {
  public sideNav = viewChild<MatSidenav>(MatSidenav);
  public user = this.store.selectSignal(userSelectors.selectUser, { equal: compareEntities });
  public title = 'fotos.axelwallau.de';

  public toggleSidenav(): void {
    this.sideNav()?.toggle().finally();
  }
}
