import { ChangeDetectionStrategy, Component, computed, inject, Signal, viewChild } from '@angular/core';
import { NavigationStart, Router, RouterLink } from '@angular/router';

import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';

import { filter } from 'rxjs';
import { gallerySelectors } from '@store/gallery/galleries.selectors';
import { routerSelectors } from '@store/router/router.selectors';

import { ReactiveComponent } from '@core/components/_base/reactive.component';
import { TGalleryTreeNode } from '@interfaces';

@Component({
  selector: 'faw-galleries-menu',
  templateUrl: './galleries-menu.component.html',
  styleUrls: ['./galleries-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenuItem, MatMenu, MatMenuTrigger, MatButton, RouterLink],
})
export class GalleriesMenuComponent extends ReactiveComponent {
  public treeData: Signal<TGalleryTreeNode[]> = this.store.selectSignal(gallerySelectors.selectTreeData);
  public activeDecade: Signal<string>;
  private menuTrigger = viewChild<MatMenuTrigger>(MatMenuTrigger);

  constructor() {
    super();

    inject(Router)
      .events.pipe(filter(ev => ev instanceof NavigationStart))
      .subscribe(() => this.menuTrigger().closeMenu());

    const $params = this.store.selectSignal(routerSelectors.selectParam('year'));

    this.activeDecade = computed(() => {
      const treeData = this.treeData();
      const params = $params();

      if (!params) {
        return treeData[0]?.label || '-';
      }

      let [startYear, endYear] = params.split('-').map(year => parseInt(year, 10));
      endYear ||= startYear;

      return treeData.find(a => a.startYear <= startYear && a.endYear >= endYear)?.label;
    });
  }
}
