import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  input,
  Output,
  signal,
} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { RouterLink } from '@angular/router';

import { MatIcon } from '@angular/material/icon';
import { MatMiniFabAnchor } from '@angular/material/button';

import { actions as userActions } from '@store/user';

import { User } from '@core/models';
import { GalleriesMenuComponent } from '../../features/galleries/components/galleries-menu/galleries-menu.component';
import { GalleriesSearchComponent } from '../../features/galleries/components/galleries-search/galleries-search.component';
import { ReactiveComponent } from '@core/components/_base/reactive.component';

@Component({
  selector: 'faw-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, MatIcon, MatMiniFabAnchor, GalleriesMenuComponent, GalleriesSearchComponent],
})
export class HeaderComponent extends ReactiveComponent implements AfterViewInit {
  public user = input<User>(null);
  @Output() emitter: EventEmitter<boolean> = new EventEmitter();

  private observer: ResizeObserver;
  private headerHeight = signal(0);

  private el = inject(ElementRef);
  private scroller = inject(ViewportScroller);

  constructor() {
    super();

    effect(() => {
      document.body.style.setProperty('--faw-header-height', `${this.headerHeight()}px`);
      const oneRem = parseFloat(getComputedStyle(document.documentElement).fontSize);
      this.scroller.setOffset([0, this.headerHeight() + 2 * oneRem]);
    });
  }

  ngAfterViewInit() {
    this.observer = new ResizeObserver(entries => {
      this.headerHeight.set(entries[0].borderBoxSize[0].blockSize);
    });

    this.observer.observe(this.el.nativeElement.querySelector('header'));
  }

  public toggleSideNav(): void {
    this.emitter.emit(true);
  }

  public logout(): void {
    this.store.dispatch(userActions.logout());
  }
}
