<button mat-button class="main" [matMenuTriggerFor]="decadesMenu">
  {{ activeDecade() }}
</button>

<mat-menu #decadesMenu="matMenu">
  @for (decade of treeData(); track $index) {
    <button mat-menu-item [matMenuTriggerFor]="yearsMenu" [routerLink]="decade.path" >
      {{ decade.label }}
    </button>

    <mat-menu #yearsMenu="matMenu">
      @for (block of decade.children; track block.label) {
        <button mat-menu-item [matMenuTriggerFor]="galleriesMenu">{{ block.label }}</button>

        <mat-menu #galleriesMenu>
          @for(gallery of block.children; track gallery.uuid) {
            <a class="label" mat-menu-item [routerLink]="block.path" [fragment]="gallery.uuid">
              {{ gallery.label }}
            </a>
          }
        </mat-menu>
      }
    </mat-menu>
  }
</mat-menu>


