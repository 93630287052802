<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Suche...</mat-label>
    <input matInput type="text" formControlName="needle" (focus)="reset()"
           #input [matAutocomplete]="auto">

    <mat-autocomplete #auto="matAutocomplete">
      @for (result of $results(); track result.uuid) {
        <mat-option (click)="exit()"
                    [class.null-result]="!result.uuid"
                    [routerLink]="result.path"
                    [fragment]="result.uuid"
                    [title]="result.label">
          {{ result.label }}
        </mat-option>
      }
    </mat-autocomplete>

  </mat-form-field>
</form>
