import { createAction, props } from '@ngrx/store';
import { NavigationExtras, Params } from '@angular/router';

type NavigationProps = { url: string; fragment?: string; queryParams?: Params; state?: NavigationExtras['state'] };

const navigate = createAction('[Router] NAVIGATE', props<NavigationProps>());

export const routerActions = {
  navigate,
};
