import {
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  Output,
  signal,
  Signal,
  untracked,
  viewChild,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';

import { MatFormField, MatInput, MatLabel } from '@angular/material/input';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';

import { galleryActions, gallerySelectors } from '@store/gallery';

import { ReactiveComponent } from '@core/components/_base/reactive.component';
import { TGalleryTreeNode } from '@interfaces';

@Component({
  selector: 'faw-galleries-search',
  templateUrl: './galleries-search.component.html',
  styleUrls: ['./galleries-search.component.scss'],
  imports: [RouterLink, MatOption, MatAutocomplete, ReactiveFormsModule, MatInput, MatAutocompleteTrigger, MatFormField, MatLabel],
})
export class GalleriesSearchComponent extends ReactiveComponent {
  @Output() selected: EventEmitter<boolean> = new EventEmitter();

  public form = new FormGroup({ needle: new FormControl<string>('') });
  public $results: WritableSignal<TGalleryTreeNode[]> = signal([]);

  private inputRef = viewChild<ElementRef<HTMLInputElement>>('input');
  private $galleries = this.bufferGalleries();

  constructor() {
    super();
    this.initFiltering();
    this.store.dispatch(galleryActions.fetchTreeData());
  }

  public reset(): void {
    this.form.reset();
    this.$results.set(null);
  }

  public exit(): void {
    this.reset();
    this.inputRef().nativeElement?.blur();
    this.selected.next(true);
  }

  private bufferGalleries() {
    return computed(() => {
      return this.store
        .selectSignal(gallerySelectors.selectTreeData)()
        .map(({ children }) => children)
        .reduce(
          (coll, pack) => {
            const items = pack.reduce(
              (block, { path, children }) => {
                return [...block, ...children.map(({ label, uuid }) => ({ label, path, uuid }))];
              },
              [] as { label: string; path: string; uuid: string }[]
            );

            return [...coll, ...items];
          },
          [] as Partial<{ startYear: number; endYear: number; year: number; label: string; path: string; uuid: string }>[]
        );
    });
  }

  private initFiltering(): void {
    const $needle: Signal<string> = toSignal(this.form.get('needle').valueChanges);

    effect(() => {
      const galleries = this.$galleries();
      const needle = $needle()?.trim()?.toLowerCase();

      untracked(() => {
        if (!needle || needle.length < 2) {
          this.$results.set(null);
          return;
        }

        const results = galleries.filter(({ label }) => label.toLowerCase().includes(needle));

        if (results?.length) {
          this.$results.set(results);
          return;
        }

        this.$results.set([{ label: 'Keine Ergebnisse', path: null, uuid: null, year: null }]);
      });
    });
  }
}
