<faw-notifications />

@if (user(); as user) {
  <faw-header (emitter)="toggleSidenav()" [user]="user" />
}

<mat-sidenav-container (backdropClick)="sideNav()?.close()">
  <mat-sidenav [fixedInViewport]="true" [autoFocus]="false">
    @if (user(); as user) {
      <faw-side-nav (closeSidebar)="sideNav().close()" />
    }
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet />
  </mat-sidenav-content>

</mat-sidenav-container>

@defer (on idle) {
  <faw-loading-spinner />
} @placeholder (minimum 2s) {
  <div></div>
}

@if (user()) {
  @defer (on viewport; on idle) {
    <faw-gallery-lightbox />
    <faw-footer />
  } @placeholder {
    <div></div>
  }
}

