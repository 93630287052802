import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';

import { tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LOCALSTORAGE_TOKEN_KEY } from '@core/constants';

export const fawInterceptor = (request: HttpRequest<any>, next: HttpHandlerFn) => {
  let clonedRequest: HttpRequest<any> = request.clone();
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);

  if (token) {
    clonedRequest = request.clone({ setHeaders: { Authorization: token } });
  }

  const url = new URL(request.url);

  if (url.pathname.includes('//')) {
    url.pathname = url.pathname.replace(/\/\//g, '/');
    clonedRequest = clonedRequest.clone({ url: url.toString() });
  }

  return next(clonedRequest).pipe(
    tap(ev => {
      if (ev instanceof HttpResponse && ev.headers.get('Authorization')) {
        localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, ev.headers.get('Authorization'));
      }
    }),
    catchError((err: HttpErrorResponse) => {
      if (err.status === 401) {
        localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
        inject(Router).navigate(['/users/login']).finally();
      }

      return throwError(() => err);
    })
  );
};
