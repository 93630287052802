import { Routes } from '@angular/router';

import { adminGuard, authGuard } from './core/guards';

export const APP_ROUTES = [
  {
    path: 'users',
    loadChildren: () => import('./features/users/user.routes'),
  },
  {
    path: 'galleries',
    loadChildren: () => import('./features/galleries/galleries.routes'),
    canActivate: [authGuard()],
  },
  {
    path: 'admin',
    loadChildren: () => import('./features/admin/admin.routes'),
    canActivateChild: [adminGuard],
  },
  { path: '**', redirectTo: '/galleries/welcome' },
] satisfies Routes;
