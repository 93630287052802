import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, switchMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { createEffect, ofType } from '@ngrx/effects';
import { BaseEffects } from '@store/base.effects';

import { galleryActions } from './galleries.actions';

import { IApiGallery, IPaginationConfig, TGalleryTreeNode } from '@interfaces';
import { routerActions } from '@store/router/router.actions';

@Injectable()
export class GalleryEffects extends BaseEffects {
  fetch$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.fetch),
      mergeMap(action => {
        const params = (['page', 'pageSize', 'sort', 'order', 'query'] as Array<keyof IPaginationConfig>).reduce((p, c) => {
          if (action[c]) {
            return typeof action[c] === 'object' ? { ...p, ...action[c] } : { ...p, [c]: action[c] };
          }
          return p;
        }, {});

        return this.api
          .get<{ entities: IApiGallery[]; total: number }>('admin/galleries', params)
          .pipe(map(({ payload, error }) => (error ? galleryActions.fetchError({ error }) : galleryActions.fetchSuccess(payload))));
      })
    )
  );

  fetchOne$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.fetchOne),
      mergeMap(({ uuid, admin, details }) => {
        const url = admin ? 'admin/galleries' : 'galleries';
        const params = details ? { details: 1 } : null;

        return this.api.get<IApiGallery>(`${url}/${uuid}`, params).pipe(
          map(({ success, payload, error }) => {
            if (success) {
              return galleryActions.fetchOneSuccess({ gallery: payload });
            }
            return galleryActions.fetchError({ error });
          })
        );
      })
    )
  );

  fetchForYears$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.fetchForYears),
      mergeMap(({ start, end, reset }) =>
        this.api.get<IApiGallery[]>('galleries', { start, end }).pipe(
          map(({ success, payload, error }) => {
            if (success) {
              return galleryActions.fetchForYearsSuccess({ galleries: payload, reset });
            }
            return galleryActions.fetchError({ error });
          })
        )
      )
    )
  );

  fetchTreeData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.fetchTreeData),
      mergeMap(() =>
        this.api.get<TGalleryTreeNode[]>('/galleries/tree').pipe(
          map(({ payload, error }) => {
            if (payload) {
              return galleryActions.fetchTreeDataSuccess({ data: payload });
            }
            return galleryActions.fetchError({ error });
          })
        )
      )
    )
  );

  create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.create),
      mergeMap(({ entity }) =>
        this.api.post<IApiGallery>('admin/galleries', { gallery: entity }).pipe(
          switchMap(({ success, payload, error }) => {
            if (success) {
              this.notify(`Created gallery '${payload.label}'`);
              return [
                galleryActions.createSuccess({ gallery: payload }),
                routerActions.navigate({ url: `/admin/galleries/${payload.id}/edit` }),
              ];
            }
            return of(galleryActions.createError({ error }));
          })
        )
      )
    )
  );

  update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.update),
      mergeMap(({ entity }) =>
        this.api.patch<IApiGallery>(`admin/galleries/${entity.uuid}`, { gallery: entity }).pipe(
          map(({ success, payload, error }) => {
            if (success) {
              this.notify(`Updated gallery '${payload.label}'`);
              return galleryActions.updateSuccess({ gallery: payload });
            }
            return galleryActions.updateError({ error });
          })
        )
      )
    )
  );

  delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(galleryActions.destroy),
      mergeMap(({ uuid }) =>
        this.api.delete<string>(`admin/galleries/${uuid}`).pipe(
          map(({ success, error }) => {
            if (success) {
              return galleryActions.destroySuccess({ uuid });
            }
            return galleryActions.destroyError({ error });
          })
        )
      )
    )
  );
}
