import { Action, ActionReducer } from '@ngrx/store';

import { MinimalRouterStateSnapshot, RouterReducerState } from '@ngrx/router-store';
import { IGalleryState } from '@store/gallery/galleries.reducer';
import { UserState } from '@store/user/user.reducer';
import { environment } from '../../environments/environment.prod';

export interface RootState {
  users: UserState;
  galleries: IGalleryState;
  router: RouterReducerState<MinimalRouterStateSnapshot>;
}

export function logger(reducer: ActionReducer<RootState>): ActionReducer<RootState> {
  return (state: RootState, action: Action): RootState => {
    const result = reducer(state, action);

    if (environment.production) {
      return result;
    }

    console.groupCollapsed(action.type);
    console.log('prev state', structuredClone(state));
    console.log('action', action);
    console.log('next state', structuredClone(result));
    console.groupEnd();

    return result;
  };
}
