import { ApplicationConfig, inject, provideAppInitializer } from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';

import { META_REDUCERS, provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { MinimalRouterStateSerializer, provideRouterStore, routerReducer } from '@ngrx/router-store';

import { APP_ROUTES } from './app-routes';

import { fawInterceptor } from '@core/interceptors/faw-interceptor';
import { EnvironmentService } from '@core/services/environment/environment.service';
import { UserService } from '@core/services/user/user.service';

import { usersFeature } from '@store/user';
import { galleriesFeature } from '@store/gallery';
import { RouterEffects } from '@store/router/router.effects';
import { UserEffects } from '@store/user/user.effects';
import { GalleryEffects } from '@store/gallery/gallery.effects';

import { logger } from './store';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatDialogRef } from '@angular/material/dialog';

const initApp = async () => {
  await inject(UserService).init();
  return EnvironmentService.initVersionCheck();
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptors([fawInterceptor])),
    provideRouter(
      APP_ROUTES,
      withComponentInputBinding(),
      withRouterConfig({ onSameUrlNavigation: 'reload', paramsInheritanceStrategy: 'always' }),
      withInMemoryScrolling({ scrollPositionRestoration: 'top' })
    ),
    provideStore({ router: routerReducer }),
    provideRouterStore({ serializer: MinimalRouterStateSerializer }),
    provideState(usersFeature),
    provideState(galleriesFeature),
    provideEffects([RouterEffects, UserEffects, GalleryEffects]),
    provideStoreDevtools(),
    provideAppInitializer(initApp),
    { provide: META_REDUCERS, useValue: logger, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always', subscriptSizing: 'dynamic' } },
    { provide: MatDialogRef, useValue: {} },
  ],
};
