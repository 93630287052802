import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@core/services/user/user.service';

export function authGuard(): CanActivateFn {
  return () => {
    if (inject(UserService).isLoggedIn()) {
      return true;
    }

    return inject(Router).createUrlTree(['/users/login']);
  };
}
