import { Component, EventEmitter, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatTree, MatTreeNode, MatTreeNodeDef, MatTreeNodePadding, MatTreeNodeToggle } from '@angular/material/tree';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { MatIconButton } from '@angular/material/button';

import { routerActions } from '@store/router/router.actions';
import { gallerySelectors } from '@store/gallery';
import { actions as userActions } from '@store/user';

import { TGalleryTreeNode } from '@interfaces';

import { GalleriesSearchComponent } from '../../features/galleries/components/galleries-search/galleries-search.component';
import { ReactiveComponent } from '@core/components/_base/reactive.component';

@Component({
  selector: 'faw-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  imports: [
    RouterLink,
    MatDivider,
    GalleriesSearchComponent,
    MatIconButton,
    MatTreeNodeDef,
    MatIcon,
    MatTreeNodeToggle,
    MatTreeNode,
    MatTreeNodePadding,
    MatTree,
  ],
})
export class SideNavComponent extends ReactiveComponent {
  @Output() closeSidebar: EventEmitter<void> = new EventEmitter();

  public dataSource = this.store.selectSignal(gallerySelectors.selectTreeData);
  public childrenAccessor = (node: TGalleryTreeNode) => node.children ?? [];

  public navigate(node: TGalleryTreeNode): void {
    this.close();
    this.store.dispatch(routerActions.navigate({ url: node.path, fragment: node.uuid }));
  }

  public hasChild(_: number, node: any): boolean {
    return !!node.children?.length;
  }

  public close(): void {
    this.closeSidebar.next();
  }

  public logout(): void {
    this.close();
    this.store.dispatch(userActions.logout());
  }
}
