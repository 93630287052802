import version from '../../../../static/version.json';

export class EnvironmentService {
  static async checkVersion(): Promise<void> {
    fetch(`/static/version.json?${Date.now()}`)
      .then(response => response.json())
      .then(({ timestamp }) => {
        if (timestamp === version.timestamp) {
          return;
        }

        const url = new URL(location.href);

        if (url.searchParams.get('reloaded')) {
          return;
        }

        url.searchParams.set('reloaded', Date.now().toString());
        window.location.href = url.toString();
      })
      .catch(console.warn);
  }

  static initVersionCheck(): boolean {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        EnvironmentService.checkVersion().finally();
      }
    });

    return true;
  }
}
