@for (notification of $notifications(); track notification.id) {
  <div class="notification {{ notification.cssExtra }}" [ngClass]="notification.type | lowercase">

    @if (notification.headline) {
      <div class="headline">
        {{ notification.headline }}
      </div>
    }

    <div class="message">
      <mat-icon>{{ iconFor(notification) }}</mat-icon>
      {{ notification.message }}
    </div>
  </div>
}


