<h3>Suche</h3>
<faw-galleries-search (selected)="close()"/>

<div class="wrapper">
  <h3>Alben</h3>

  <mat-tree #tree [dataSource]="dataSource()" [childrenAccessor]="childrenAccessor">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <div (click)="navigate(node)">
        {{node.label}}
      </div>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodeToggle
                   [cdkTreeNodeTypeaheadLabel]="node.label">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.label">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ tree.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      {{ node.label }}
    </mat-tree-node>
  </mat-tree>
</div>

<mat-divider/>

<ul class="footer">
  <li>
    <a routerLink="/users/profile" (click)="close()">
      <mat-icon>settings</mat-icon>
      Mein Profil
    </a>
  </li>
  <li>
    <span (click)="logout()" title="Ausloggen"><mat-icon>input</mat-icon> Ausloggen</span>
  </li>
</ul>
