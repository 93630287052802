import { bootstrapApplication } from '@angular/platform-browser';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app-config';

import { EnvironmentService } from '@core/services/environment/environment.service';

EnvironmentService.checkVersion().finally(() => {
  bootstrapApplication(AppComponent, appConfig).catch(console.error);
});
