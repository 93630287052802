@if (user(); as user) {
  <header class="mat-elevation-z2">
    <nav id="main-nav">
      <ul>
        <li>
          <a routerLink="/" title="Startseite">
            <img src="/assets/images/img_logo_full.svg" id="logo" />
          </a>
        </li>

        @if (user.isAdmin) {
          <li><a routerLink="/admin">
            <mat-icon>settings</mat-icon>
            <span class="d-none d-lg-inline">Admin</span></a>
          </li>
        }

        <li class="desktop">
          <a routerLink="/users/profile" title="Mein Profil">
            <mat-icon>person</mat-icon>
            <span class="user-name d-none d-lg-inline">{{ user.firstName }}</span>
          </a>
        </li>

        <li class="menu desktop">
          <faw-galleries-menu />
        </li>

        <li class="search desktop">
          <faw-galleries-search />
        </li>

        <li class="logout desktop">
          <a (click)="logout()" title="Ausloggen" mat-mini-fab class="mat-elevation-z0">
            <mat-icon>input</mat-icon>
          </a>
        </li>

        <li class="mobile-menu" (click)="toggleSideNav()">
          <mat-icon>more_vert</mat-icon>
        </li>
      </ul>
    </nav>
  </header>
}
