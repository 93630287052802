import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

import { ApiService, NotificationService } from '@core/services';
import { NotificationTypeEnum } from '@interfaces';

@Injectable({ providedIn: 'root' })
export class BaseEffects {
  protected api = inject(ApiService);
  protected actions$ = inject(Actions);
  protected notificationService = inject(NotificationService);

  protected notify(message: string, notificationType: NotificationTypeEnum = NotificationTypeEnum.INFO, headline: string = null): void {
    this.notificationService.show(message, notificationType, headline);
  }
}
